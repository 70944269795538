import React from "react"
import { Container, Row, Col } from "reactstrap"
import NavBar from "../components/header/nav-main"
import springIcon1 from "../images/spring/logo-spring-initializr-tr.png"
import springIcon2 from "../images/spring/spring-boot-solace-logo.png"
import springIcon3 from "../images/spring/spring-cloud-solace-logo.png"
import Footer from "../components/footer"

// Imports CSS
import "../css/main.css"

const SpringHelpPage = props => {
  return (
    <main>
      <NavBar />
      <Container>
        <Row style={{ paddingTop: `40px`, paddingBottom: `20px` }}>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <h1
              style={{
                fontSize: `42px`,
                paddingBottom: `10px`,
              }}
            >
              How Solace works with Spring Initializr at start.spring.io
            </h1>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              <img src={springIcon1} alt="" style={{ float: `right` }} />
              So, you used Spring Initializr to generate your Spring Boot
              project AND you read HELP.md? Nice!
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              If you came across this page some other way be sure to check out{" "}
              <a
                href="https://solace.com/blog/solace-pubsub-spring-initializr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                how you can use Spring Initializr to generate your Solace +
                Spring projects
              </a>
              .
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              There are a few different scenarios that could play out when
              generating a project with Solace PubSub+ as a dependency using
              Spring Initializr.
            </p>
          </Col>
        </Row>
        <Row style={{ paddingTop: `20px`, paddingBottom: `20px` }}>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <img src={springIcon2} alt="" />
          </Col>
          <Col xs="10" sm="10" md="10" lg="10" xl="10">
            <h2
              style={{
                fontSize: `32px`,
                paddingBottom: `10px`,
              }}
            >
              Scenario 1: Your dependencies include Solace PubSub+, but do not
              require a binder.
            </h2>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              In this scenario Spring Initializr added the
              solace-spring-boot-starter to your project. This starter allows
              you to use Spring Boot with either the Solace JMS API or the
              Solace Java API (JCSMP).
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              <strong>I want to use the Solace JMS API;</strong>
            </p>
            <p>
              <a
                href="https://github.com/SolaceProducts/solace-spring-boot/tree/master/solace-spring-boot-starters/solace-jms-spring-boot-starter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solace JMS API Starter in Github
              </a>
              <br />{" "}
              <a
                href="https://tutorials.solace.dev/spring/spring-boot-autoconfigure/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started with a Tutorial
              </a>
              <br />
              <a
                href="https://github.com/SolaceProducts/solace-spring-boot/tree/master/solace-spring-boot-samples/solace-jms-sample-app"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sample Application
              </a>
              <br />
              <a
                href="https://github.com/SolaceProducts/solace-spring-boot/tree/master/solace-spring-boot-samples/solace-jms-sample-app-jndi"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sample Application w/ JNDI
              </a>
              <br />
              <a
                href="https://docs.solace.com/API-Developer-Online-Ref-Documentation/jms/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Javadocs
              </a>
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              <strong>I want to use the Solace Java API (JCSMP);</strong>
            </p>
            <p>
              <a
                href="https://github.com/SolaceProducts/solace-spring-boot/tree/master/solace-spring-boot-starters/solace-java-spring-boot-starter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Starter in Github
              </a>
              <br />{" "}
              <a
                href="https://github.com/SolaceProducts/solace-spring-boot/tree/master/solace-spring-boot-samples/solace-java-sample-app"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sample Application
              </a>
              <br />
              <a
                href="https://docs.solace.com/API-Developer-Online-Ref-Documentation/java/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Javadocs
              </a>
            </p>
          </Col>
        </Row>
        <Row style={{ paddingTop: `20px`, paddingBottom: `20px` }}>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <img src={springIcon3} alt="" />
          </Col>
          <Col xs="10" sm="10" md="10" lg="10" xl="10">
            <h2
              style={{
                fontSize: `32px`,
                paddingBottom: `10px`,
              }}
            >
              Scenario 2: Your dependencies include Solace PubSub+ AND Cloud
              Stream, which states that it requires a binder.
            </h2>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              Let us explain some of the magic that you just witnessed occur. In
              this scenario the Spring Initializr at start.spring.io is smart
              enough to parse the dependencies, see that you have selected both
              Solace PubSub+ and Cloud Stream, and realize you're trying to
              create a Spring Cloud Stream microservice. Because of this it
              includes the Solace Spring Cloud Stream Binder Starter in your
              project.
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              <strong>
                I want to create a Spring Cloud Stream Microservice;
              </strong>
            </p>
            <p>
              <a
                href="https://github.com/SolaceProducts/solace-spring-cloud/tree/master/solace-spring-cloud-starters/solace-spring-cloud-stream-starter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Binder Github Repo
              </a>
              <br />
              <a
                href="https://tutorials.solace.dev/spring/spring-cloud-stream/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started with a Tutorial
              </a>
              <br />
              <a
                href="https://github.com/SolaceProducts/solace-spring-cloud/tree/master/solace-spring-cloud-starters/solace-spring-cloud-stream-starter#creating-a-simple-solace-binding"
                target="_blank"
                rel="noopener noreferrer"
              >
                Creating a Simple Solace Binding
              </a>
              <br />
              <a
                href="https://github.com/SolaceProducts/solace-spring-cloud/tree/master/solace-spring-cloud-starters/solace-spring-cloud-stream-starter#configuration-options"
                target="_blank"
                rel="noopener noreferrer"
              >
                Binder Configuration Options
              </a>
            </p>
          </Col>
        </Row>
        <Row style={{ paddingTop: `20px`, paddingBottom: `60px` }}>
          <Col xs="2" sm="2" md="2" lg="2" xl="2">
            <span>
              <i
                className="far fa-frown fa-7x"
                aria-hidden="true"
                style={{ color: `#00ad93` }}
              ></i>
            </span>
          </Col>
          <Col xs="10" sm="10" md="10" lg="10" xl="10">
            <h2
              style={{
                fontSize: `32px`,
                paddingBottom: `10px`,
              }}
            >
              Scenario 3: You didn’t include Solace PubSub+ as a dependency.
            </h2>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              Well this is the disappointing option. Check out the{" "}
              <a
                href="https://www.solace.dev"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solace Developer Portal
              </a>{" "}
              to learn more about PubSub+ and you might just change your mind.
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              Learn more about how to use{" "}
              <a
                href="https://solace.com/spring"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solace with Spring
              </a>
              .
            </p>
            <p
              style={{
                fontSize: `18px`,
              }}
            >
              Have any questions!? Discussion them in the{" "}
              <a
                href="https://solace.community"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solace Developer Community
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </main>
  )
}

export default SpringHelpPage
